<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Customers</h3>
            </div>
            <div class="card-toolbar">
              <div class="row">
                <div class="col-7">
                  <b-form-input v-model="filterCustomer" type="search" placeholder="Type to search"></b-form-input>
                </div>
                <div class="col-5">
                  <b-button variant="outline-primary" v-b-modal.add-customer-modal>Add Customer</b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body body-fluid">
            <div class="table-responsive">
              <b-table striped hover :items="users" :fields="fields" :filter="filterCustomer"
                >
                <template #cell(unique_id)="row">
                  <a class="a" @click="GetRecord(row.item.id)">
                    {{ row.item.unique_id }}
                  </a>
                </template>

                <template #cell(kwc_required)="row">
                  <template v-if="row.item.kwc_required == 0">
                    <span class="badge badge-pill badge-info">Verified</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-pill badge-warning text-white">Pending</span>
                  </template>
                </template>
                <template #cell(id)="row" style="width: 100px;">
                  <b-button style="width: 30px;" @click="showEditModal(row.item.id)" size="sm" variant="secondary"><i
                      class="flaticon-edit icon-sm"></i></b-button>
                  <!-- <b-button style="width: 30px;" @click="deleteCustomer(row.item.id)" class="ml-2" variant="danger" size="sm"><i
                    class="flaticon2-cross icon-sm"></i> </b-button>
                <b-button style="width: 30px;" v-if="row.item.kwc_required == 1" @click="acceptSpdoc(row.item)" class="ml-2" variant="success"
                  size="sm"><i class="flaticon2-check-mark icon-sm"></i> </b-button> -->
                </template>
              </b-table>
              <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right"
                    aria-controls="shipments-tbl"></b-pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="add-customer-modal" ref="modal" size="lg" @ok="addNewCustomer" title="Add Customer">
      <div class="row">
        <div class="col-md-12 pt-2">
          <b-form-group v-slot="{ ariaDescribedby }">
            <div class="row">
              <div class="col-md-4">
                <label class="font-weight-bold">Account Type</label>
              </div>
              <div class="col-md-4">
                <b-form-radio v-model="customer.type" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="1">Personal</b-form-radio>
              </div>
              <div class="col-md-4">
                <b-form-radio v-model="customer.type" :aria-describedby="ariaDescribedby" name="some-radios"
                  value="2">Business</b-form-radio>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="col-md-12 pt-2">
          <label class="font-weight-bold">Customer Name</label>
          <b-form-input placeholder="Customer Name" v-model="customer.first_name"></b-form-input>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">Phone Number</label>
          <b-form-input placeholder="Phone number" v-model="customer.phone"></b-form-input>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">Email</label>
          <b-form-input placeholder="Email address" v-model="customer.email"></b-form-input>
        </div>
        <div class="col-md-12 pt-2">
          <label class="font-weight-bold">Address</label>
          <b-form-input id="straddress" type="text" placeholder="Street Address"
            v-model="customer.address"></b-form-input>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">City</label>
          <b-form-input id="city" type="text" placeholder="City" v-model="customer.city"></b-form-input>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">Country</label>
          <b-form-select :options="countries" value-field="id" text-field="name" v-model="customer.country"
            @change="changeCountry" size="md" class=""></b-form-select>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">State</label>
          <b-form-select :options="allstates" value-field="id" text-field="name" v-model="customer.state"
            @change="changeState" size="md" class="">
            <template #first>
              <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
            </template>
          </b-form-select>
        </div>
        <div class="col-md-6 pt-2">
          <label class="font-weight-bold">Postal Code</label>
          <b-form-input id="postal" type="text" v-model="customer.postal_code" placeholder="Postal Code"></b-form-input>
        </div>
      </div>
    </b-modal>

    <b-modal id="edit-customer-modal" ref="modal" size="lg" @ok="updateCustomer" title="Edit Customer">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 pt-2">
              <b-form-group v-slot="{ ariaDescribedby }">
                <div class="row">
                  <div class="col-md-4">
                    <label class="font-weight-bold">Account Type</label>
                  </div>
                  <div class="col-md-4">
                    <b-form-radio v-model="customer.type" :aria-describedby="ariaDescribedby" name="some-radios"
                      value="1">Personal</b-form-radio>
                  </div>
                  <div class="col-md-4">
                    <b-form-radio v-model="customer.type" :aria-describedby="ariaDescribedby" name="some-radios"
                      value="2">Business</b-form-radio>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="customer.type == 1">
              <b-form-group label="First Name">
                <b-form-input v-model="customer.first_name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="customer.type == 1">
              <b-form-group label="Last Name">
                <b-form-input v-model="customer.last_name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4" v-if="customer.type == 2">
              <b-form-group label="Business Name">
                <b-form-input v-model="customer.business_name"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Email">
                <b-form-input v-model="customer.email"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Phone">
                <b-form-input v-model="customer.phone"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Address">
                <b-form-input v-model="customer.address"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 pt-2">
              <label class="font-weight-bold">City</label>
              <b-form-input id="city" type="text" placeholder="City" v-model="customer.city"></b-form-input>
            </div>
            <div class="col-md-6 pt-2">
              <label class="font-weight-bold">Country</label>
              <b-form-select :options="countries" value-field="id" text-field="name" v-model="customer.country"
                @change="changeCountry" size="md" class=""></b-form-select>
            </div>
            <div class="col-md-6 pt-2">
              <label class="font-weight-bold">State</label>
              <b-form-select :options="allstates" value-field="id" text-field="name" v-model="customer.state"
                @change="changeState" size="md" class="">
                <template #first>
                  <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
                </template>
              </b-form-select>
            </div>
            <div class="col-md-6 pt-2">
              <label class="font-weight-bold">Postal Code</label>
              <b-form-input id="postal" type="text" v-model="customer.postal_code"
                placeholder="Postal Code"></b-form-input>
            </div>

            <!-- <div class="col-md-4">
              <b-form-group label="Document Verification?">
                <b-form-checkbox v-model="customer.kwc_required"></b-form-checkbox>
              </b-form-group>
            </div> -->
          </div>
        </div>
      </div>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import singaporePostalCode from "@/assets/data/singaporePostalCode.json";
import countryCodes from "@/assets/data/countryCodes.json";
export default {
  data() {
    return {
      users: [],
      type: 0,
      rows: 0,
      perPage: 30,
      isLoading: false,
      filterCustomer: null,
      currentPage: 1,
      // addCustomer: {
      //   first_name: null,
      //   email: null,
      //   phone: null,
      //   name_prefix: "Mr",
      //   business_name: "",
      //   last_name: "",
      //   email: "",
      //   password: "",
      //   country_code_phone: "+65",
      //   phone: "",
      //   address: "",
      //   city: "",
      //   postal_code: "",
      //   // country: this.countries.length ? this.countries[0].id : null,
      //   country: null,
      //   state: null,
      //   branch_id: null,
      //   type: 1,
      // },
      isLoading: false,
      nameprefixes: [{ name: "Mr" }, { name: "Mrs" }, { name: "Ms" }],
      countrycodes: countryCodes,
      singaportpostalcodes: singaporePostalCode,
      states: [],
      allstates: [],
      countries: [],
      customer: {},
      fields: [
        {
          key: "name",
          label: "Name",
          formatter: (value, key, item) => {
            if (item.type == 1) {
              return item.first_name + " " + item.last_name;
            } else {
              return item.business_name;
            }
          },
        },
        { key: "unique_id", label: "Unique ID" },
        { key: "email", label: "Email" },
        { key: "address", label: "Address" },
        { key: "city", label: "City" },
        { key: "branch", label: "Branch" },
        { key: "phone", label: "Phone" },

        {
          key: "kwc_required",
          label: "Verification",
          formatter: (value) => {
            if (value == 0) {
              return "Verified";
            } else {
              return "Not Verified";
            }
          },
        },
        { key: "id", label: "Action" },
        // { key: "purchase_date", label: "Booking Date" },
      ],
      baseUrl: process.env.VUE_APP_FILE_URL,
    };
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Customers" }]);
    this.getAllUsers();
    this.getAllCountries();
    this.getAllBranches();
    this.getAllStates();
  },
  methods: {
    getAllUsers() {
      this.isLoading = true;
      ApiService.get("getallcustomers")
        .then(({ data }) => {
          this.users = data;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },
    deleteCustomer(id) {
      this.isLoading = true;
      ApiService.delete("deletecustomer", id)
        .then(() => {
          this.getAllUsers();
          this.makeToastVariant(
            "warning",
            "Customer deleted successfully",
            "Success"
          );
        })
        .catch(() => { });
    },
    showEditModal(id) {
      this.customer = this.users.filter((x)=> x.id == id)[0];
      debugger;
      console.log(id);
      this.$bvModal.show("edit-customer-modal");
    },
    checkCustomerErrors() {
      if (
        this.customer.first_name &&
        this.customer.email &&
        this.customer.phone &&
        this.customer.address
      ) {
        return true;
      }

      this.errors = [];
      if (!this.customer.first_name) {
        this.errors.push("Customer Name is required.");
      }
      if (!this.customer.email) {
        this.errors.push("Email is required.");
      }
      if (!this.customer.phone) {
        this.errors.push("Phone is required.");
      }
      if (!this.customer.address) {
        this.errors.push("Address is required.");
      }

      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    addNewCustomer() {
      if (this.checkCustomerErrors() == true) {
        this.isLoading = true;
        this.customer.role = 6;
        this.customer.password = 'Indobo@123';
        this.customer.branch_id = 0;

        ApiService.post("addCstomer", this.customer)
          .then((response) => {
            this.$nextTick(() => {
              this.makeToastVariant(
                "success",
                "Customer added successfully",
                "Success"
              );

              this.getAllUsers();
              this.isLoading = false;
              this.showCustomerForm = false;
              this.customer = {};
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    updateCustomer(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isLoading = true;
      if (this.customer.kwc_required) {
        this.customer.kwc_required = 1;
      }
      ApiService.update("updateuser", this.customer.id, this.customer)
        .then(() => {
          this.getAllUsers();
          this.$nextTick(() => {
            this.$bvModal.hide("edit-customer-modal");
            this.makeToastVariant(
              "info",
              "Customer updated successfully",
              "Success"
            );
          });
        })
        .catch(({ response }) => {
          this.isLoading = false;
          for (let field of Object.keys(response.data.error)) {
            this.makeToastVariant(
              "danger",
              response.data.error[field][0],
              "Error"
            );
          }
        });
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showFile(item) {
      if (item != "") {
        window.open(this.baseUrl + item, "_blank");
      }
    },
    GetRecord(id) {
      this.$router.push({
        name: "cdashboard",
        params: {
          id: id,
        },
      });
    },
    changeState() {
      ApiService.get("getStatesByCountryId", this.customer.country)
        .then(({ data }) => {

          // this.allstates = data;
          this.allstates = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changePostalCode() {

      if (
        this.countries.filter(
          (x) => x.id == this.customer.country && x.name == "Singapore"
        ).length > 0 &&
        this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.customer.postal_code
        ).length > 0
      ) {
        this.customer.address = this.singaportpostalcodes.filter(
          (f) => f.POSTAL == this.customer.postal_code
        )[0].ADDRESS;
        this.customer.city = "Singapore";
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getAllBranches() {
      ApiService.get("branch")
        .then(({ data }) => {

          // context.commit(SET_AUTH, data.success);
          this.branches = data;
          if (
            this.branches.filter(
              (x) => x.name.toLowerCase() == "Singapore".toLowerCase()
            ).length > 0
          ) {
            this.customer.branch_id = this.branches.filter(
              (x) => x.name.toLowerCase() == "Singapore".toLowerCase()
            )[0].id;
          }

        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllStates() {
      ApiService.get("getstates")
        .then(({ data }) => {

          this.allstates = data;
          this.states = data;

          if (
            this.allstates.filter((x) => x.country_id == this.customer.country)
              .length > 0
          ) {
            this.states = this.allstates.filter(
              (x) => x.country_id == this.customer.country
            );
          }
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changeCountry() {
      this.changeState();
    },
    getAllCountries() {
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
          this.customer.country = this.countries.length
            ? this.countries[0].id
            : null;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changeCountryCode(countrycode) {

      var countryPhoneCode = this.countrycodes.filter((x) => x.code == countrycode)[0];
      this.customer.country_code_phone = countryPhoneCode.dial_code;

      if (this.countries.filter((x) => x.code == countryPhoneCode.code).length > 0) {
        this.customer.country = this.countries.filter((x) => x.code == countryPhoneCode.code)[0].id;
      }
      this.changeCountry();
    },
    acceptSpdoc(item) {
      ApiService.get("acceptSupportingDoc", item.id)
        .then((response) => {
          debugger;
          if (response.status) {
            this.getAllUsers();
          } else {
            this.makeToastVariant(
              "danger",
              response.message,
              "Error"
            );
          }
        })
        .catch(({ response }) => {
          this.isLoading = false;
          this.makeToastVariant(
            "danger",
            response.message,
            "Error"
          );
        });
    },
  },
};
</script>
